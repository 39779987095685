import { createClient } from '@supabase/supabase-js';
import { isNumber } from 'lodash';
import { HOSTNAME } from '../../lib/config';
import { processStream } from './stream';
import {
  SIGNED_URL_VALIDITY,
  SUPABASE_PUBLIC_KEY,
  SUPABASE_URL,
  REDEEM_CODE_VALIDITY,
  MAX_SIGNED_URL_VALIDITY,
} from '../constants/supabase.constants';
import {
  EXPERIENCES_TABLE,
  EXPERIENCE_CONTENT_TABLE,
  GEOXP_AUDIO_BUCKET,
  PATTERNS_TABLE,
  USER_EXPERIENCES_TABLE,
} from '../constants/db.constants';

// **** CLIENT ****
export const supabase = createClient(SUPABASE_URL, SUPABASE_PUBLIC_KEY);

// **** USER ****
export const signInWithEmail = async ({ email }) => {
  const { data, error } = await supabase.auth.signInWithOtp({ email });
  return { data, error };
};

export const signInWithEmailAndPassword = async ({ email, password }) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  return { data, error };
};

export const resetPasswordEmail = async ({ email }) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: `${HOSTNAME}` });
  return { data, error };
};

export const updateUserPassword = async ({ password, passwordConfirm }) => {
  if (!password) {
    console.error('updateUserPassword - provide a password');
    return { error: 'Password missing' };
  }

  if (password !== passwordConfirm) {
    console.error('updateUserPassword - passwords do not match');
    return { error: 'Passwords do not match' };
  }

  const { data, error } = await supabase.auth.updateUser({ password });
  return { data, error };
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  return { error };
};

export const getSupabaseSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  return { data: data.session, error };
};

export const getCompleteUserProfile = async () => {
  const { data, error } = await supabase.functions.invoke('user-profile');
  return { data, error };
};

export const checkAdmin = async () => {
  const { data, error } = await supabase.rpc('get_user_role_id');
  const isAdmin = data === 1;
  return { isAdmin, error };
};

export const updateUser = async ({ attributes, id }) => {
  if (!attributes) {
    console.error('updateUser - provide an userAttributes object');
    return { error: 'Provide an userAttributes object' };
  }

  const { data, error } = await supabase.from('profiles').update(attributes).eq('id', id);

  return { data, error };
};

export const inviteUserWithCode = async ({ email, role, code }) => {
  if (!email || !role || !code) {
    console.error('inviteUserWithCode - Incorrect data provided');
    return { error: 'Incorrect data provided' };
  }

  const { data, error } = await supabase.functions.invoke('invite-new-user', {
    body: JSON.stringify({ email, role, code }),
  });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

export const sendUpgradeCode = async ({ email, role, roleLabel, code }) => {
  if (!email || !role || !code) {
    console.error('sendUpgradeCode - Incorrect data provided');
    return { error: 'Incorrect data provided' };
  }

  const { data, error } = await supabase.functions.invoke('generate-upgrade-code', {
    body: JSON.stringify({
      email,
      role,
      roleLabel,
      code,
    }),
  });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

// *** DB QUERIES ***
export const dbInsert = async ({ table, insertData }) => {
  const { data, error } = await supabase.from(table).insert(insertData).select();
  return { data, error };
};

export const singleDbInsert = async ({ table, insertData }) => {
  const { data, error } = await supabase.from(table).insert(insertData).select().single();
  return { data, error };
};

export const dbUpsert = async ({ table, upsertData, upsertOptions }) => {
  const { data, error } = await supabase.from(table).upsert(upsertData, upsertOptions).select();
  return { data, error };
};

export const singleDbUpsert = async ({ table, upsertData }) => {
  const { data, error } = await supabase.from(table).upsert(upsertData).select().single();
  return { data, error };
};

export const dbSelect = async ({ table }) => {
  const { data, error } = await supabase.from(table).select('*');
  return { data, error };
};

export const dbSelectByUser = async ({ table, userId }) => {
  const { data, error } = await supabase.from(table).select('*').eq('user_id', userId);
  return { data, error };
};

// **** BUCKETS ***
export const listBuckets = async () => {
  const { data, error } = await supabase.storage.listBuckets();
  return { data, error };
};

export const getBucketInfo = async (bucketId) => {
  const { data, error } = await supabase.storage.getBucket(bucketId);
  return { data, error };
};

// **** CUSTOM HELPERS ***
export const uploadFile = async ({ bucketId, file, filename }) => {
  const { data, error } = await supabase.storage.from(bucketId).upload(filename, file, {
    cacheControl: '3600',
    upsert: false,
  });
  return { data, error };
};

export const removeFile = async ({ bucketId, filename }) => {
  const { data, error } = await supabase.storage.from(bucketId).remove([filename]);
  return { data, error };
};

export const updateFile = async ({ bucketId, file, filename }) => {
  const { data, error } = await supabase.storage.from(bucketId).update(filename, file, {
    cacheControl: '3600',
    upsert: true,
  });
  return { data, error };
};

export const getAudioFileUrl = async ({ bucketId, filePath }) => {
  const { data, error } = await supabase.storage
    .from(bucketId)
    .createSignedUrl(filePath, SIGNED_URL_VALIDITY);
  return { data, error };
};

export const getGeoXpConfig = async ({ experienceId }) => {
  if (!experienceId) {
    console.error('getGeoXpConfig - no experienceId provided');
    return { error: 'No experienceId provided' };
  }

  const { data, error } = await supabase.functions.invoke('get-geoxp-config', {
    body: JSON.stringify({ experienceId }),
  });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

export const getUserExperiences = async ({ userId }) => {
  const { data, error } = await supabase
    .from(USER_EXPERIENCES_TABLE)
    .select(
      `
      *,
      experiences!inner(
        is_active,
        name,
        owner_id,
        created_at,
        is_default,
        profiles!experiences_owner_id_fkey(name, surname)
      )
    `
    )
    .eq('user_id', userId)
    .not('is_enabled', 'is', false)
    .eq('experiences.is_canceled', false);

  return { data, error };
};

export const getExperienceDetails = async ({ experienceId }) => {
  const { data, error } = await supabase.from('experiences').select('*').eq('id', experienceId);

  return { data, error };
};

export const getUserOwnedExperiencesWithoutDefault = async ({ userId }) => {
  const { data, error } = await supabase
    .from('experiences')
    .select('*')
    .eq('owner_id', userId)
    .not('is_default', 'is', true)
    .eq('is_canceled', false);
  return { data, error };
};

export const getUserOwnedExperiences = async ({ userId }) => {
  const { data, error } = await supabase
    .from('experiences')
    .select('*')
    .eq('owner_id', userId)
    .eq('is_canceled', false);
  return { data, error };
};

export const getUserOwnedExperience = async ({ experienceId }) => {
  const { data, error } = await supabase.functions.invoke('get-experience-details', {
    body: JSON.stringify({ experienceId }),
  });
  return { data, error };
};

export const getExperienceSpotsCount = async ({ experienceId }) => {
  const { count, data, error } = await supabase
    .from('experience_content_spots')
    .select('*, geoxp_spots!inner(*)', { count: 'exact', head: false })
    .eq('experience_id', experienceId)
    .eq('is_canceled', false)
    .eq('geoxp_spots.is_canceled', false)
    .eq('geoxp_spots.is_draft', false);
  return { count, data, error };
};

export const downloadGeoXpExperience = async ({ experienceId }) => {
  if (!experienceId) {
    console.error('downloadGeoXpExperience - no experienceId provided');
    return { error: 'No experienceId provided' };
  }

  const { data, error } = await supabase.functions.invoke('download-geoxp-experience', {
    body: JSON.stringify({ experienceId }),
  });

  return { data, error };
};

export const shareExperience = async (payload) => {
  const { email, experienceId } = payload;

  if (!email || !experienceId) {
    console.error('shareExperience - no experienceId or user email provided');
    return { error: 'No data provided' };
  }

  const { data, error } = await supabase.functions.invoke('share-experience', {
    body: JSON.stringify(payload),
  });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

export const getSpotTypes = async () => {
  const { data, error } = await supabase.from('spot_types').select('*').eq('is_active', true);
  return { data, error };
};

export const checkNewAgamiManualCode = async ({ code, experiences }) => {
  const { data, error } = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .select('*, geoxp_spots!inner(*)')
    .in('experience_id', experiences)
    .eq('manual_code', code)
    .eq('geoxp_spots.is_canceled', false);
  return { available: data?.length === 0, error };
};

export const checkExistingAgamiManualCode = async ({ code, experiences, spotId }) => {
  const { data, error } = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .select('*, geoxp_spots!inner(*)')
    .in('experience_id', experiences)
    .eq('manual_code', code)
    .eq('geoxp_spots.is_canceled', false)
    .neq('geoxp_spots.id', spotId);
  return { available: data?.length === 0, error };
};

export const getExperiencesForSpot = async ({ spot_id }) => {
  const { data, error } = await supabase.from(EXPERIENCE_CONTENT_TABLE).select('*').eq('spot_id', spot_id);
  return { data, error };
};

export const cancelManyExperienceContent = async ({ ids }) => {
  if (!ids || ids?.length === 0) {
    return { error: 'Missing exp ID array' };
  }

  const { data, error } = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .update({ is_canceled: true })
    .in('id', ids)
    .select();
  return { data, error };
};

export const checkRedeemCode = async ({ code }) => {
  if (!code) {
    console.error('checkRedeemCode - provide redeem code');
    return { error: 'Provide redeem code' };
  }

  const { data, error } = await supabase.from('redeem_codes').select('*').eq('code', code);
  return { data, error };
};

// UPGRADE CODE
export const redeemUpgradeCode = async ({ email, code }) => {
  if (!email || !code) {
    console.error('redeemUpgradeCode - provide user email and redeem code');
    return { error: 'Provide user email and redeem code' };
  }

  const { data, error } = await supabase
    .from('redeem_codes')
    .update({ redeemed: true, redeemed_at: new Date().toISOString() })
    .eq('user_email', email)
    .eq('code', code)
    .eq('redeemed', false)
    .select();

  if (data.length === 0) {
    return {
      error: 'Your code is invalid or has already been used! Contact us for a new code.',
    };
  }

  const issueDate = new Date(data[0].issued_at).getTime();

  const now = Date.now();

  const diff = (now - issueDate) / (1000 * 3600 * 24); // days

  if (diff > REDEEM_CODE_VALIDITY) {
    return {
      error: 'Your code older than 30 days. Contact us for a new code.',
    };
  }

  return { data: data[0], error };
};

export const getCodesList = async () => {
  const { data, error } = await supabase.from('redeem_codes').select();
  return { data, error };
};

export const setSkipOnboarding = async ({ userId }) => {
  const { data, error } = await supabase.from('profiles').update({ skip_onboarding: true }).eq('id', userId);
  return { data, error };
};

export const deleteExperience = async ({ experienceId }) => {
  // step 1 - delete experience
  const expTableUpdate = await supabase
    .from(EXPERIENCES_TABLE)
    .update({ is_canceled: true })
    .eq('id', experienceId)
    .select();

  if (expTableUpdate.error) {
    console.error(expTableUpdate.error);
    return {
      error: expTableUpdate.error,
    };
  }

  // step 2 - delete agami-experience relations associated with this experience
  const expContentTableUpdate = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .update({ is_canceled: true })
    .eq('experience_id', experienceId)
    .select();

  if (expContentTableUpdate.error) {
    console.error(expContentTableUpdate.error);
    return {
      error: expContentTableUpdate.error,
    };
  }

  return {
    data: {
      expTable: expTableUpdate.data,
      expContentTable: expContentTableUpdate.data,
    },
  };
};

export const getAdminAgamiList = async ({ search, range, sort }) => {
  const { data, error } = await supabase.functions.invoke('get-admin-spots', {
    body: JSON.stringify({ search, range, sort }),
  });

  if (!error) {
    return { ...data };
  }

  const { body } = error.context;

  if (!body) {
    return { ...data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      ...data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { ...data, error };
};

export const removeAgamiFromExperience = async ({ spotId, experienceId }) => {
  if (!spotId || !experienceId) {
    return { error: 'Missing spotId or experienceId' };
  }

  const { data, error } = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .update({ is_canceled: true })
    .eq('experience_id', experienceId)
    .eq('spot_id', spotId)
    .select();

  return { data, error };
};

export const getUserExpAccessCodes = async ({ userId }) => {
  const { data, error } = await supabase
    .from('access_codes')
    .select('*')
    .eq('created_by', userId)
    .not('is_deleted', 'is', true);
  return { data, error };
};

export const createExpAccessCode = async (formData) => {
  if (!formData?.experienceId) {
    return {
      error: 'Must provide an experience to generate code',
    };
  }

  const payload = {
    ...formData,
    ...(formData.expiresAt && { expiresAt: new Date(formData.expiresAt).toISOString() }),
  };

  const { data, error } = await supabase.functions.invoke('access-code-create', {
    body: JSON.stringify(payload),
  });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

export const deleteAccessCode = async ({ accessCodeId }) => {
  if (!accessCodeId) {
    return { error: 'Missing accessCodeId ' };
  }
  const { data, error } = await supabase
    .from('access_codes')
    .update({ is_deleted: true })
    .eq('id', accessCodeId)
    .select();
  return { data, error };
};

export const getUserPatterns = async ({ user }) => {
  if (!user?.id) {
    return { error: 'Missing user' };
  }

  if (user.role_id !== 1 && user.role_id !== 2 && user.role_id !== 4) {
    return { error: 'Unauthorized user' };
  }

  const { data, error } = await supabase
    .from(PATTERNS_TABLE)
    .select('*')
    .eq('creator_id', user.id)
    .not('is_deleted', 'is', true);
  return { data, error };
};

export const createOrEditPattern = async ({ upsertData }) => {
  if (!upsertData) {
    console.error('createPattern - no upsertData provided');
    return { error: 'No upsertData provided' };
  }

  // insert in pattenrs tables
  const { data, error } = await singleDbUpsert({
    table: PATTERNS_TABLE,
    upsertData,
  });

  return { data, error };
};

export const deleteUserPattern = async ({ patternId }) => {
  if (!patternId) {
    return { error: 'Missing patternId ' };
  }
  const { data, error } = await supabase
    .from(PATTERNS_TABLE)
    .update({ is_deleted: true })
    .eq('pattern_id', patternId)
    .select();
  return { data, error };
};

export const getUserPatternDetails = async ({ patternId }) => {
  if (!patternId) {
    return { error: 'Missing patternId' };
  }

  const { data, error } = await supabase.functions.invoke('get-pattern-details', {
    body: JSON.stringify({ patternId }),
  });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

export const removeAgamiFromPattern = async ({ agamiId, patternId, experienceId }) => {
  if (!agamiId || !patternId || !experienceId) {
    return { error: 'Missing agamiId, patternId or experienceId' };
  }
  const { data, error } = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .update({ pattern_id: null })
    .eq('pattern_id', patternId)
    .eq('experience_id', experienceId)
    .eq('spot_id', agamiId)
    .select();
  return { data, error };
};

export const addAgamiToPattern = async ({ agamiId, patternId, experienceId }) => {
  if (!agamiId || !patternId || !experienceId) {
    return { error: 'Missing agamiId, patternId or experienceId' };
  }
  const { data, error } = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .update({ pattern_id: patternId })
    .eq('experience_id', experienceId)
    .eq('spot_id', agamiId)
    .select();
  return { data, error };
};

export const generateAgamiAudioSignedUrl = async ({ audioPath, expiresIn }) => {
  if (!audioPath) {
    return {
      error: 'Specify the path to the Agami audio content to generate a signed URL.',
    };
  }

  if (!expiresIn || !isNumber(expiresIn)) {
    return {
      error: 'Provide the validity duration (in seconds) for the Agami audio signed URL.',
    };
  }

  if (expiresIn > MAX_SIGNED_URL_VALIDITY) {
    return {
      error: 'Unable to generate URLs with a validity period exceeding 30 days.',
    };
  }

  const data = await supabase.storage.from(GEOXP_AUDIO_BUCKET).createSignedUrl(audioPath, expiresIn);

  return data;
};

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-tailwind/react';
import truncate from 'lodash/truncate';
import { PiCheckCircleDuotone, PiPlusBold } from 'react-icons/pi';
import { upsertAgamiExperiences } from '../utils/helpers/upload.helpers';
import { useGeneralToast } from '../hooks/toast';
import { useSessionContext } from '../contexts/SessionContext';
import AgamiTypeChip from '../components/AgamiTypeChip';
import { removeManualCodeLibrarySuffix } from '../utils/helpers/agami.helpers';
import { formatDateString } from '../utils/helpers/dates.helpers';

const AddAgamiToExpRow = ({
  id,
  name,
  description,
  type,
  inserted_at,
  experience_content_spots,
  currentExpId,
  refetch,
}) => {
  const { error: toastError } = useGeneralToast();
  const { user } = useSessionContext();
  const [adding, setAdding] = useState(false);
  const agamiExpRelationIds = experience_content_spots?.map((expRel) => expRel.experience_id) ?? [];
  const manualCode = removeManualCodeLibrarySuffix(experience_content_spots?.[0]?.manual_code);

  const addAgamiToExp = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setAdding(true);

      const newExperiences = agamiExpRelationIds.concat([currentExpId]);

      const { error } = await upsertAgamiExperiences({
        spot_id: id,
        type,
        manual_code: manualCode,
        newExperiences,
        userDefaultExpId: user.defaultExperienceId,
      });

      if (error) {
        toastError(error);
        setAdding(false);
        return;
      }

      await refetch();
      setAdding(false);
    },
    [agamiExpRelationIds, currentExpId, id, type, manualCode]
  );

  const alreadyPresent = agamiExpRelationIds?.includes(currentExpId);

  return (
    <tr key={id} className="even:bg-blue-gray-50/50 text-center">
      <td className="py-2">
        <Typography title={name} variant="small" className="font-black">
          {truncate(name, {
            separator: ' ',
          })}
        </Typography>
      </td>
      <td className="py-2">
        <Typography title={description} variant="small">
          {truncate(description, {
            separator: ' ',
          })}
        </Typography>
      </td>
      <td className="py-2">
        <Typography title={`Agami type  ${type}`} variant="small">
          <AgamiTypeChip agamiType={type} />
        </Typography>
      </td>
      <td className="py-2">
        <Typography variant="small">{formatDateString(inserted_at)}</Typography>
      </td>
      <td className="py-2">
        {alreadyPresent ? (
          <span>
            <PiCheckCircleDuotone className="mx-auto text-green-800 text-[20px] my-1" />
          </span>
        ) : (
          <IconButton
            variant="text"
            size="sm"
            color="yellow"
            onClick={(e) => addAgamiToExp(e)}
            disabled={adding}
          >
            <PiPlusBold className="text-primary text-[14px]" />
          </IconButton>
        )}
      </td>
    </tr>
  );
};

AddAgamiToExpRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.number,
  inserted_at: PropTypes.string,
  experience_content_spots: PropTypes.arrayOf(
    PropTypes.shape({ experience_id: PropTypes.string, manual_code: PropTypes.string })
  ),
  currentExpId: PropTypes.string,
  refetch: PropTypes.func,
};

export default AddAgamiToExpRow;

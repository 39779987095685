import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { supabase } from '../utils/helpers/supabase.helpers';

export const useExperienceSharingInfo = ({ experienceId }) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [experienceSharingInfo, setExperienceSharingInfo] = useState(null);

  const fetchExperienceSharingInfo = useCallback(async () => {
    if (!experienceId || !user) return;

    setIsLoading(true);

    const { data, error } = await supabase.functions.invoke('get-experience-sharing-info', {
      body: JSON.stringify({ experienceId }),
    });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setExperienceSharingInfo(data);
    setIsLoading(false);
  }, [user, experienceId]);

  useEffect(() => {
    if (!user || !experienceId) {
      return;
    }
    fetchExperienceSharingInfo();
  }, [user, experienceId]);

  return { experienceSharingInfo, isLoading, refetch: fetchExperienceSharingInfo };
};

import React from 'react';
import { AGAMI_VIZ_MODE_DEFAULT, AGAMI_VIZ_MODE_KEY } from '../utils/constants/localStorage.constants';
import { useStoredState } from './useStoredState';
import { useScreenBreakpoint } from './toolkit';

export const useAgamiVizMode = () => {
  const screenBreakpoint = useScreenBreakpoint();

  const [vizMode, setVizMode] = useStoredState({
    key: AGAMI_VIZ_MODE_KEY,
    defaultValue: AGAMI_VIZ_MODE_DEFAULT,
  });

  React.useEffect(() => {
    if (screenBreakpoint === 'sm') {
      setVizMode('cards');
    }
  }, [screenBreakpoint]);

  return [vizMode, setVizMode];
};

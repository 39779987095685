import React from 'react';
import {
  Input,
  IconButton,
  Typography,
  Button,
  Spinner,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Alert,
} from '@material-tailwind/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PiCheckBold, PiClipboard, PiSiren, PiTimer, PiWarningCircle } from 'react-icons/pi';
import { AccordionCaret } from './AccordionCaret';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import { MAX_SIGNED_URL_VALIDITY, SIGNED_URL_VALIDITY } from '../utils/constants/supabase.constants';
import { useGeneralToast } from '../hooks/toast';
import { useAgamiAudioSignedUrlGenerate } from '../hooks/useAgamiAudioSignedUrlGenerate';
import { AgamiSpotDetailPropType } from '../PropTypes';
import { formatDateTimeString } from '../utils/helpers/dates.helpers';
import { useAgamiAudioSignedUrlDelete } from '../hooks/useAgamiAudioSignedUrlDelete';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const AgamiPreGeneratedSignedUrl = ({ agami }) => {
  const toast = useGeneralToast();
  const [accordionOpen, setAccordionOpen] = React.useState(false);
  const adminFeats = useFeatureAuth(FEATURES.ADMIN_PANEL);

  const [agamiSignedUrl, setAgamiSignedUrl] = React.useState(agami?.audio_signed_url);
  const [agamiSignedUrlExpiration, setAgamiSignedUrlExpiration] = React.useState(
    agami?.audio_signed_url_expires_at
  ); // ISO string

  const [expiresIn, setExpiresIn] = React.useState(SIGNED_URL_VALIDITY);
  const expiresInValid = Number.isInteger(expiresIn);
  const expired = agamiSignedUrlExpiration && Date.now() > new Date(agamiSignedUrlExpiration).getTime();

  const [copied, setCopied] = React.useState(false);

  const copyUrlToClipboard = async () => {
    await navigator.clipboard.writeText(agamiSignedUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const { generateUrl, isLoading } = useAgamiAudioSignedUrlGenerate({
    agamiId: agami?.id,
    onSuccess: ({ signedUrl, signedUrlExpiresAt }) => {
      setAgamiSignedUrl(signedUrl);
      setAgamiSignedUrlExpiration(signedUrlExpiresAt);
      toast.success('Signed URL generated successfully');
    },
    onError: () => toast.error('Error generating signed URL'),
  });

  const { deleteUrl, isLoading: isDeleting } = useAgamiAudioSignedUrlDelete({
    agamiId: agami?.id,
    onSuccess: () => {
      toast.success('Expired URL deleted successfully');
      setAgamiSignedUrl(null);
      setAgamiSignedUrlExpiration(null);
    },
    onError: () => toast.error('Error deleting signed URL'),
  });

  return (
    <Accordion open={accordionOpen} icon={<AccordionCaret rotate={accordionOpen} />}>
      <AccordionHeader
        onClick={() => setAccordionOpen((prev) => !prev)}
        className="justify-center gap-4 text-md"
      >
        Signed URL
      </AccordionHeader>
      <AccordionBody>
        {!adminFeats && <>Feature not available</>}

        {adminFeats && !agami && (
          <Alert variant="ghost" color="orange">
            You will be able to generate a signed URL right after finishing the upload.
          </Alert>
        )}

        {adminFeats && agami && (
          <>
            <Typography className="mb-4">
              Pre-generating a signed URL can improve loading performance, especially for experiences with a
              large number of agamis, as it avoids real-time generation when users download experience
              configurations and allows the URL to be reused by all users accessing the experience.
            </Typography>

            {agamiSignedUrl && (
              <>
                <Typography className="font-bold mb-2">Current signed URL</Typography>
                <div className="relative flex w-full gap-1 justify-center items-center mb-1">
                  <Input
                    className="w-full"
                    value={agamiSignedUrl}
                    type="text"
                    label="Signed URL"
                    readOnly
                    error={expired}
                  />
                  <IconButton
                    color={copied ? 'green' : 'accent'}
                    onClick={copyUrlToClipboard}
                    variant={copied ? 'text' : 'solid'}
                    disabled={copied || expired}
                  >
                    {copied ? <PiCheckBold className="h-6 w-6" /> : <PiClipboard className="h-4 w-4" />}
                  </IconButton>
                </div>

                {expired ? (
                  <Alert color="red" size="sm" variant="ghost" icon={<PiSiren className="w-6 h-6" />}>
                    <Typography className="font-bold mb-2">
                      This URL has expired and no longer grants access to the audio file. You can either
                      delete it or generate a new one.
                    </Typography>

                    <Button size="sm" color="red" onClick={deleteUrl} disabled={isDeleting}>
                      {isDeleting ? <Spinner className="h-4 w-4" /> : 'Delete expired URL'}
                    </Button>
                  </Alert>
                ) : (
                  <Alert color="green" variant="ghost" icon={<PiTimer className="w-6 h-6" />}>
                    <span className="font-bold">URL valid until</span>{' '}
                    <em>{formatDateTimeString(agamiSignedUrlExpiration)}</em>
                  </Alert>
                )}
              </>
            )}

            {(!agamiSignedUrl || expired) && (
              <div className="grid grid-cols-2 gap-x-2 mt-2">
                <div className="col-span-2 mb-2">
                  <Typography className="font-bold">Generate a new URL</Typography>
                </div>

                <Input
                  value={expiresIn ?? ''}
                  type="number"
                  inputMode="numeric"
                  label="Expires in (seconds)"
                  max={MAX_SIGNED_URL_VALIDITY}
                  size="sm"
                  onChange={(e) => {
                    if (e.currentTarget.value === '') setExpiresIn(null);
                    else setExpiresIn(Number(e.currentTarget.value));
                  }}
                  error={!expiresInValid}
                />

                <Button
                  disabled={!expiresInValid || isLoading}
                  size="sm"
                  color="orange"
                  className="flex items-center justify-center"
                  onClick={() =>
                    generateUrl({
                      expiresIn,
                      audioPath: agami?.audio_content_path,
                    })
                  }
                >
                  {isLoading ? <Spinner className="h-4 w-4" /> : 'Generate'}
                </Button>

                <div className="col-span-2">
                  {!expiresInValid && (
                    <Typography variant="small" color="red">
                      Please insert a numeric value.
                    </Typography>
                  )}
                  {expiresIn && expiresIn <= MAX_SIGNED_URL_VALIDITY && (
                    <Typography variant="small">{dayjs.duration(expiresIn, 'seconds').humanize()}</Typography>
                  )}
                  {expiresIn > MAX_SIGNED_URL_VALIDITY && (
                    <Typography variant="small" color="red">
                      Max allowed value is {MAX_SIGNED_URL_VALIDITY}
                    </Typography>
                  )}
                </div>
              </div>
            )}

            <Alert
              className="mt-4"
              variant="ghost"
              color="orange"
              icon={<PiWarningCircle className="w-6 h-6" />}
            >
              <ul className="ml-2 list-inside list-disc text-sm">
                <li>
                  A <strong>signed URL</strong> is a URL containing an authentication token, allowing public
                  access to the content for a specified duration.
                </li>
                <li>Once shared, a signed URL cannot be revoked and remains accessible until it expires.</li>
                <li>
                  If not pre-generated, a signed URL will be dynamically generated for each user accessing the
                  experience, with a fixed validity of 3 hours, after which it will be discarded.
                </li>
                <li>When the pre-generated URL created here expires, it becomes invalid and is ignored.</li>
                <li>Use signed URLs carefully and set the duration according to your specific use case.</li>
              </ul>
            </Alert>
          </>
        )}
      </AccordionBody>
    </Accordion>
  );
};

AgamiPreGeneratedSignedUrl.propTypes = {
  agami: AgamiSpotDetailPropType,
};

export default AgamiPreGeneratedSignedUrl;

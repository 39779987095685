import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';
import AddAgamiToExpRow from './AddAgamiToExpRow';
import { AgamiSpotDetailPropType } from '../PropTypes';

const TABLE_HEAD = ['Agami Name', 'Description', 'Type', 'Created', 'Add to experience'];

const AddAgamiToExperienceTable = ({ currentExpId, refetchAfterChange, agamiList }) => (
  <table className="w-full">
    <thead>
      <tr>
        {TABLE_HEAD.map((head) => (
          <th key={head} className="border-blue-gray-100 bg-blue-gray-500/30 border-b p-4">
            <Typography variant="small" color="primary" className="font-bold leading-none">
              {head}
            </Typography>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {agamiList.map(({ id, name, description, type, inserted_at, experience_content_spots }) => (
        <AddAgamiToExpRow
          key={id}
          id={id}
          name={name}
          description={description}
          type={type}
          inserted_at={inserted_at}
          experience_content_spots={experience_content_spots}
          currentExpId={currentExpId}
          refetch={refetchAfterChange}
        />
      ))}
    </tbody>
  </table>
);

AddAgamiToExperienceTable.propTypes = {
  currentExpId: PropTypes.string,
  refetchAfterChange: PropTypes.func,
  agamiList: PropTypes.arrayOf(AgamiSpotDetailPropType),
};

export default AddAgamiToExperienceTable;

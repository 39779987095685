import React from 'react';
import dayjs from 'dayjs';
import { generateAgamiAudioSignedUrl, singleDbUpsert } from '../utils/helpers/supabase.helpers';
import { GEOXP_SPOTS_TABLE } from '../utils/constants/db.constants';
import { useFeatureAuth } from './authorization';
import { FEATURES } from '../utils/constants/features.constants';

export const useAgamiAudioSignedUrlGenerate = ({ agamiId, onSuccess, onError }) => {
  const adminFeats = useFeatureAuth(FEATURES.ADMIN_PANEL);
  const [isLoading, setIsLoading] = React.useState(false);

  const manageError = React.useCallback(
    (error) => {
      setIsLoading(false);
      console.error(error);
      if (typeof onError === 'function') {
        onError(error);
      }
    },
    [onError]
  );

  const generateUrl = React.useCallback(
    async ({ audioPath, expiresIn }) => {
      try {
        if (!adminFeats) {
          manageError('Operation not allowed');
          return;
        }

        if (!agamiId) {
          manageError('Invalid agami ID');
          return;
        }

        setIsLoading(true);

        // get signed URL with desired duration
        const { data: urlGenerationData, error: urlGenerationError } = await generateAgamiAudioSignedUrl({
          audioPath,
          expiresIn,
        });

        if (urlGenerationError) {
          manageError(urlGenerationError);
          return;
        }

        const { signedUrl } = urlGenerationData ?? {};

        // calculate audio_signed_url_expires_at timestamp (UTC)
        const signedUrlExpiresAt = dayjs().add(expiresIn, 'seconds').toISOString();

        // upsert DB record to save URL and expiration date
        const { error: upsertError } = await singleDbUpsert({
          table: GEOXP_SPOTS_TABLE,
          upsertData: {
            id: agamiId,
            audio_signed_url: signedUrl,
            audio_signed_url_expires_at: signedUrlExpiresAt,
          },
        });

        if (upsertError) {
          manageError(upsertError);
          return;
        }

        setIsLoading(false);

        if (typeof onSuccess === 'function') {
          onSuccess({
            signedUrl,
            signedUrlExpiresAt,
          });
        }
      } catch (err) {
        manageError(err);
      }
    },
    [adminFeats, agamiId, manageError, generateAgamiAudioSignedUrl, singleDbUpsert, onSuccess]
  );

  return {
    generateUrl,
    isLoading,
  };
};

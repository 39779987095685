import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  IconButton,
  Typography,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
  Select,
  Option,
  Spinner,
  Alert,
} from '@material-tailwind/react';
import { PiShareNetworkLight, PiPencilSimpleLight, PiDetective } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { shareExperience } from '../utils/helpers/supabase.helpers';
import { useGeneralToast } from '../hooks/toast';
import { useSessionContext } from '../contexts/SessionContext';
import { useDevice } from '../hooks/device';
import { ExperienceDataPropType } from '../PropTypes';
import { useExperienceSharingInfo } from '../hooks/useExperienceSharingInfo';

const EXP_ROLES = {
  1: 'Owner',
  2: 'Editor',
  3: 'Listener',
};

const TABLE_HEAD = ['User', 'Role', ''];

const ShareExperiencePanel = ({ experience, onSuccess }) => {
  const { platformType } = useDevice();

  const { success: toastSuccess, error: toastError } = useGeneralToast();

  const { user } = useSessionContext();

  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [targetRole, setTargetRole] = useState();
  const [isEditing, setIsEditing] = useState(false);

  const [sharingUpsertData, setSharingUpsertData] = useState({
    experienceId: experience.id,
    email: '',
    sharingId: null,
  });

  const { experienceSharingInfo, isLoading, refetch } = useExperienceSharingInfo({
    experienceId: experience?.id,
  });

  const performShare = async (revoke) => {
    if (sharingUpsertData.email === user.email) {
      toastError("You can't share it with yourself :)");
      return;
    }

    setLoading(true);
    setEmailError(false);

    const { data, error } = await shareExperience({
      ...sharingUpsertData,
      role: Number(targetRole),
      revoke,
    });

    if (error) {
      console.error(error);
      if (error === 'User not found') {
        setEmailError(true);
      }
      toastError(typeof error === 'string' ? error : 'Error sharing experience');
      setLoading(false);
      return;
    }

    console.info({ data });
    toastSuccess(revoke ? 'Revoked successfully' : 'Shared successfully');
    setShowDialog(false);
    setLoading(false);
    refetch();
    if (typeof onSuccess === 'function') onSuccess();
  };

  const startNewSharing = () => {
    setIsEditing(false);
    setSharingUpsertData((prev) => ({
      ...prev,
      sharingId: null,
      email: '',
    }));
    setShowDialog(true);
  };

  const performNewSharing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    performShare();
  };

  const startEditSharing = ({ userEmail, sharingId }) => {
    setIsEditing(true);
    setSharingUpsertData((prev) => ({
      ...prev,
      sharingId,
      email: userEmail,
    }));
    setShowDialog(true);
  };

  const performEditSharing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const revoke = e.nativeEvent?.submitter?.dataset?.revoke;
    performShare(revoke);
  };

  return (
    <>
      <Dialog
        size={platformType === 'mobile' ? 'xl' : 'xs'}
        open={showDialog}
        handler={() => setShowDialog((cur) => !cur)}
        className="bg-transparent shadow-none porcodio"
      >
        <Card className="mx-auto w-full max-w-full lg:max-w-[24rem] text-primary flex flex-col">
          <form onSubmit={isEditing ? performEditSharing : performNewSharing}>
            <CardBody className="flex flex-col gap-4">
              <Typography variant="h4">Share experience</Typography>
              {isEditing && (
                <Typography className="mb-3 font-normal" variant="paragraph">
                  Edit sharing settings for this user.
                </Typography>
              )}

              {!isEditing && (
                <Typography className="mb-3 font-normal" variant="paragraph">
                  Enter the email of the user you want to share the experience with.
                </Typography>
              )}
              <Input
                value={sharingUpsertData.email}
                label="Email"
                size="lg"
                onChange={(e) => {
                  setEmailError(false);
                  setSharingUpsertData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
                disabled={loading || isEditing}
                error={emailError}
                required
              />
              <Select
                label="Role"
                onChange={(value) => setTargetRole(value)}
                value={targetRole}
                disabled={loading}
              >
                <Option value="2">EDITOR</Option>
                <Option value="3">LISTENER</Option>
              </Select>
            </CardBody>
            <CardFooter className="pt-0 w-full">
              {isEditing && (
                <div className="flex items-center gap-2">
                  <Button
                    type="submit"
                    size="sm"
                    fullWidth
                    disabled={loading}
                    className="flex justify-center"
                  >
                    {loading ? <Spinner className="h-4 w-4" /> : 'Update role'}
                  </Button>
                  <Button
                    type="submit"
                    data-revoke
                    color="red"
                    size="sm"
                    fullWidth
                    disabled={loading}
                    className="flex justify-center"
                  >
                    {loading ? <Spinner className="h-4 w-4" /> : 'Revoke'}
                  </Button>
                </div>
              )}

              {!isEditing && (
                <Button type="submit" fullWidth disabled={loading} className="flex justify-center">
                  {loading ? <Spinner className="h-4 w-4" /> : 'Share'}
                </Button>
              )}
            </CardFooter>
          </form>
        </Card>
      </Dialog>

      <div className="flex items-center justify-start gap-4">
        <Typography variant="h5">SHARING CENTER</Typography>
        <Button size="sm" disabled={loading} onClick={startNewSharing}>
          <PiShareNetworkLight />
        </Button>
      </div>

      <div className="my-4">
        {isLoading && (
          <div className="w-100 flex justify-center">
            <Spinner className="h-6 w-6" />
          </div>
        )}

        {!isLoading &&
          experienceSharingInfo.sharedWith.length === 0 &&
          experienceSharingInfo.anonymouShareCount === 0 && (
            <Typography variant="small" color="primary" className="font-bold leading-none">
              You haven&apos;t shared this experience with anyone yet.
            </Typography>
          )}

        {!isLoading && experienceSharingInfo.sharedWith.length > 0 && (
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-500/30 p-4">
                    <Typography variant="small" color="primary" className="font-bold leading-none">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {experienceSharingInfo.sharedWith.map((sharing) => (
                <tr key={sharing.sharingId} className="even:bg-blue-gray-50/50">
                  <td className="p-4">
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {sharing.userEmail}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      <Chip className="inline-block" value={EXP_ROLES[sharing.role]} />
                    </Typography>
                  </td>
                  <td className="p-4 text-right">
                    <IconButton
                      size="sm"
                      variant="text"
                      color="blue-gray"
                      onClick={() => startEditSharing(sharing)}
                    >
                      <PiPencilSimpleLight className="w-4 h-4" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!isLoading && experienceSharingInfo.anonymouShareCount > 0 && (
          <Alert color="blue" variant="ghost" icon={<PiDetective className="w-6 h-6" />}>
            <Typography variant="h6" className="font-bold">
              Guest Users: {experienceSharingInfo.anonymouShareCount}
            </Typography>
            <Typography color="primary" className="leading-tight mt-2 text-sm">
              This experience is currently shared with {experienceSharingInfo.anonymouShareCount} guest{' '}
              {experienceSharingInfo.anonymouShareCount > 1 ? 'users' : 'user'}. When you generate and share a{' '}
              <strong>guest access code</strong>, it allows others to join. Guest access remains available for
              30 days of activity. You can manage your access codes from{' '}
              <Link to="/access-codes">
                <span className="underline">this page</span>
              </Link>
              .
            </Typography>
          </Alert>
        )}
      </div>
    </>
  );
};

ShareExperiencePanel.propTypes = {
  experience: ExperienceDataPropType,
  onSuccess: PropTypes.func,
};

export default ShareExperiencePanel;

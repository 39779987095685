// API URL
export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL ?? 'https://rrgjomluctgaypahcsyf.supabase.co';

// PUBLIC KEY
export const SUPABASE_PUBLIC_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY ?? '';

export const SIGNED_URL_VALIDITY = 600; // seconds

export const REDEEM_CODE_VALIDITY = 30; // days

export const MAX_SIGNED_URL_VALIDITY = 3.37e6; // seconds (30 days)

import { APP_NAME, IS_PROD } from '../../lib/config';

const ENV_SUFFIX = IS_PROD ? '-prod' : '-test';

export const MAP_STYLE_KEY = `mezzoforte:${APP_NAME}:mapstyle${ENV_SUFFIX}`;
export const MAP_3D_KEY = `mezzoforte:${APP_NAME}:3dmap${ENV_SUFFIX}`;

export const AGAMI_VIZ_MODE_KEY = `mezzoforte:${APP_NAME}:agamivizmode${ENV_SUFFIX}`;
export const AGAMI_VIZ_MODE_DEFAULT = 'cards';

export const EXP_SETTINGS_SIDEBAR_KEY = `mezzoforte:${APP_NAME}:expsettingssidebar${ENV_SUFFIX}`;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Alert, Input, Typography, Button, Spinner, Textarea } from '@material-tailwind/react';
import { PiCircleDashed } from 'react-icons/pi';
import { useSessionContext } from '../contexts/SessionContext';
import { cleanFileName, upsertAgamiSpot } from '../utils/helpers/upload.helpers';
import SpotPositionSelector from './SpotPositionSelector';
import SpotManualCodeSelector from './SpotManualCodeSelector';
import SpotPositionPreview from '../components/SpotPositionPreview';
import { AGAMI_GLOBAL_EXPERIENCE_ID } from '../utils/constants/db.constants';
import { useGeneralToast } from '../hooks/toast';
import AudioFileDropzone from './AudioFileDropzone';
import { useFeatureAuth } from '../hooks/authorization';
import { useFormData } from '../hooks/form';
import { FEATURES } from '../utils/constants/features.constants';
import AgamiTypeSelector from './AgamiTypeSelector';
import AgamiVisibilitySelector from './AgamiVisibilitySelector';
import AgamiExperienceSelector from './AgamiExperienceSelector';
import { useUserExperiences } from '../hooks/useUserExperiences';
import { removeManualCodeLibrarySuffix } from '../utils/helpers/agami.helpers';
import { AgamiSpotDetailPropType } from '../PropTypes';
import AgamiPreGeneratedSignedUrl from '../components/AgamiPreGeneratedSignedUrl';

const UploadAgamiInterface = ({
  uploadSizeLimit,
  allowedFileTypes,
  availableSpotTypes,
  agami,
  refetchAgami,
}) => {
  const { user } = useSessionContext();
  const { error: toastError, success: toastSuccess } = useGeneralToast();
  const [audioFile, setAudioFile] = useState(null);
  const navigate = useNavigate();

  const manualSpotsAuth = useFeatureAuth(FEATURES.MANUAL_SPOTS);
  const { experienceList: userExperiences } = useUserExperiences({
    excludeDefaultExperience: true,
  });

  const existingSpotEdit = useMemo(() => !!agami, [agami]);

  const [searchParams] = useSearchParams();
  const addToExperience = searchParams.get('addToExperience');

  const initialFormData = useMemo(() => {
    // if editing an existing agami
    if (existingSpotEdit && agami) {
      return {
        experiences: agami.experience_content_spots?.map((exp) => exp.experience_id),
        spotName: agami.name,
        description: agami.description,
        latitude: agami.latitude,
        longitude: agami.longitude,
        radius: agami.radius,
        deadband: agami.deadband,
        type: agami.type,
        manual_code: removeManualCodeLibrarySuffix(agami.experience_content_spots[0]?.manual_code),
      };
    }
    // if creating a new agami
    const experiences = addToExperience
      ? [addToExperience, user.defaultExperienceId]
      : [user.defaultExperienceId];

    return {
      experiences,
      spotName: '',
      description: '',
      latitude: '',
      longitude: '',
      radius: '',
      deadband: '',
      type: 1,
      manual_code: '',
    };
  }, [existingSpotEdit, agami, addToExperience]);

  const { formData, setFormData, handleDataChange, checkInputIsValid } = useFormData(initialFormData);

  // if user has a private library, give the chance to chose between public and private
  const showMakePublicOption = user.defaultExperienceId !== AGAMI_GLOBAL_EXPERIENCE_ID;

  // already in global exp (so already public)?
  const isInGlobalExp = agami?.experience_content_spots
    ?.map((exp) => exp.experience_id)
    ?.includes(AGAMI_GLOBAL_EXPERIENCE_ID);

  const [isPublic, setIsPublic] = useState(!showMakePublicOption || isInGlobalExp);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (isPublic && !formData.experiences.includes(AGAMI_GLOBAL_EXPERIENCE_ID)) {
      handleDataChange('experiences', (currExps) => [...currExps, AGAMI_GLOBAL_EXPERIENCE_ID]);
    }

    if (!isPublic && formData.experiences.includes(AGAMI_GLOBAL_EXPERIENCE_ID)) {
      handleDataChange('experiences', (currExps) => currExps.filter((e) => e !== AGAMI_GLOBAL_EXPERIENCE_ID));
    }
  }, [isPublic, formData.experiences]);

  const onSuccess = useCallback(() => {
    setUploading(false);

    // new upload
    if (!agami?.id) {
      toastSuccess('Successful upload!');
      navigate('/');
      return;
    }

    // edit
    toastSuccess('Successful edit!');
    if (typeof refetchAgami === 'function') refetchAgami();
    window.scrollTo(0, 0);
  }, [setUploading, toastSuccess]);

  const confirmUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const is_draft = e?.nativeEvent?.submitter?.name === 'draft';

    if (!existingSpotEdit && !audioFile) {
      toastError('You need to upload an audio file!');
      return;
    }

    if (isEmpty(formData)) {
      return;
    }

    setUploading(true);

    const audioFilename = cleanFileName({ audioFile, userId: user.id });

    upsertAgamiSpot({
      ...formData,
      userDefaultExpId: user.defaultExperienceId,
      spotId: agami?.id,
      audioContentPath: agami?.audio_content_path,
      spotName: formData.spotName.trim(),
      userId: user.id,
      audioFile,
      audioFilename,
      is_draft,
      onSuccess,
      onError: (error) => {
        console.error(error);
        toastError(`Error uploading your agami: ${error.message}`);
        setUploading(false);
      },
    });
  };

  const submitButtonDisabled = uploading || (formData.type === 2 && !formData.manual_code);

  const confirmButtonLabel = useMemo(
    () => (isPublic ? 'CONFIRM AND MAKE PUBLIC' : 'CONFIRM AND SAVE'),
    [isPublic]
  );

  const canDraftAgami = useFeatureAuth(FEATURES.DRAFT_AGAMI);
  const adminFeats = useFeatureAuth(FEATURES.ADMIN_PANEL);

  const draftButtonLabel = useMemo(() => {
    if (!agami) return 'SAVE AS DRAFT';
    if (!agami?.is_draft) return 'CONVERT TO DRAFT';
    return 'SAVE EDITS AND KEEP AS DRAFT';
  }, [agami]);

  return (
    <div className="w-full md:w-4/5 lg:w-11/12 my-10">
      {agami?.is_draft && (
        <Alert variant="ghost" className="mb-4" icon={<PiCircleDashed className="h-6 w-6" />}>
          This is a draft <em>agami</em>. You can edit it, but it will not be published until you confirm it.
        </Alert>
      )}

      <form className="flex flex-col gap-4 px-3 md:px-0" onSubmit={confirmUpload}>
        <AudioFileDropzone
          spot={agami}
          uploadSizeLimit={uploadSizeLimit}
          allowedFileTypes={allowedFileTypes}
          audioFile={audioFile}
          setAudioFile={setAudioFile}
          existingSpotEdit={existingSpotEdit}
        />

        <div className="mt-1" />

        <Typography className="font-bold text-primary">Agami Metadata</Typography>

        <Input
          className="w-full"
          value={formData.spotName}
          onChange={(e) => handleDataChange('spotName', e.currentTarget.value)}
          type="text"
          label="Name (e.g. 'My awesome agami')"
          required
          disabled={uploading}
        />

        <Textarea
          className="w-full"
          rows={1}
          value={formData.description}
          onChange={(e) => handleDataChange('description', e.currentTarget.value)}
          type="text"
          label="Description (optional)"
          disabled={uploading}
        />

        {userExperiences?.length > 0 && (
          <>
            <div className="mt-1" />
            <AgamiExperienceSelector
              userExperiences={userExperiences}
              formData={formData}
              setFormData={setFormData}
            />
          </>
        )}

        {showMakePublicOption && (
          <>
            <div className="mt-1" />
            <AgamiVisibilitySelector isPublic={isPublic} setIsPublic={setIsPublic} formData={formData} />
          </>
        )}

        {manualSpotsAuth && (
          <>
            <div className="mt-1" />
            <AgamiTypeSelector
              isPublic={isPublic}
              formData={formData}
              handleDataChange={handleDataChange}
              availableSpotTypes={availableSpotTypes}
            />
          </>
        )}

        {/* GEO TYPE */}
        {formData.type === 1 && (
          <>
            <div className="mt-1" />
            <SpotPositionSelector
              formData={formData}
              setFormData={setFormData}
              handleDataChange={handleDataChange}
              checkInputIsValid={checkInputIsValid}
              uploading={uploading}
            />
            <SpotPositionPreview
              interactive
              latitude={formData.latitude}
              setLatitude={(lat) => handleDataChange('latitude', lat)}
              longitude={formData.longitude}
              setLongitude={(lon) => handleDataChange('longitude', lon)}
              radius={formData.radius}
              deadband={formData.deadband}
            />
          </>
        )}

        {/* MANUAL TYPE */}
        {formData.type === 2 && (
          <>
            <div className="mt-1" />
            <SpotManualCodeSelector
              formData={formData}
              uploading={uploading}
              handleDataChange={handleDataChange}
              spotId={agami?.id}
            />
          </>
        )}

        {adminFeats && agami && (
          <AgamiPreGeneratedSignedUrl
            agami={agami}
            formData={formData}
            setFormData={setFormData}
            uploading={uploading}
            handleDataChange={handleDataChange}
            checkInputIsValid={checkInputIsValid}
            existingSpotEdit={existingSpotEdit}
          />
        )}

        <div className="flex flex-col mt-8 justify-between  gap-4">
          <Button type="submit" name="save" value="save" className="w-full" disabled={submitButtonDisabled}>
            {uploading ? <Spinner className="h-4 w-4 mx-auto" /> : confirmButtonLabel}
          </Button>

          {canDraftAgami && (
            <Button
              type="submit"
              name="draft"
              value="draft"
              className="w-full"
              variant="outlined"
              color="primary"
              disabled={submitButtonDisabled}
            >
              {uploading ? <Spinner className="h-4 w-4 mx-auto" /> : draftButtonLabel}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

UploadAgamiInterface.propTypes = {
  agami: AgamiSpotDetailPropType,
  refetchAgami: PropTypes.func,
  uploadSizeLimit: PropTypes.number,
  availableSpotTypes: PropTypes.shape({
    [PropTypes.number]: PropTypes.string,
  }),
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default UploadAgamiInterface;

import ShortUniqueId from 'short-unique-id';
import { MANUAL_SPOT_LIBRARY_SUFFIX } from '../constants/agami.constants';

const { randomUUID } = new ShortUniqueId({ length: 16 });

export const createManualCodeLibrarySuffix = (code) => {
  if (!code) return null;
  // generate unique random ID of 16 charachters
  const randomId = randomUUID();
  // append ot code with fixed suffix
  return `${code}${MANUAL_SPOT_LIBRARY_SUFFIX}${randomId}`;
};

export const removeManualCodeLibrarySuffix = (code) => {
  if (!code) return;
  // Create a regular expression pattern based on the updated schema
  const pattern = new RegExp(`${MANUAL_SPOT_LIBRARY_SUFFIX}[A-Za-z0-9]{16}`);
  // Use replace method to remove the matching part of the string
  const result = code.replace(pattern, '');
  // return
  return result;
};

export const checkLibraryManualCode = (code) => {
  // Create a regular expression pattern based on the updated schema
  const pattern = new RegExp(`${code}${MANUAL_SPOT_LIBRARY_SUFFIX}[A-Za-z0-9]{16}`);
  return pattern.test(code);
};

export const checkManualCodeInLibrary = ({ code, spots }) => {
  // Create a regular expression pattern based on the updated schema
  const pattern = new RegExp(`${code}${MANUAL_SPOT_LIBRARY_SUFFIX}[A-Za-z0-9]{16}`);
  // Check if any element in the "spots" array matches the pattern
  const matchingSpot = spots.find((element) => pattern.test(element));
  // Return true if a matching element is found, otherwise return false
  return !!matchingSpot;
};

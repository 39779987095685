import React from 'react';
import { singleDbUpsert } from '../utils/helpers/supabase.helpers';
import { GEOXP_SPOTS_TABLE } from '../utils/constants/db.constants';
import { useFeatureAuth } from './authorization';
import { FEATURES } from '../utils/constants/features.constants';

export const useAgamiAudioSignedUrlDelete = ({ agamiId, onSuccess, onError }) => {
  const adminFeats = useFeatureAuth(FEATURES.ADMIN_PANEL);
  const [isLoading, setIsLoading] = React.useState(false);

  const manageError = React.useCallback(
    (error) => {
      setIsLoading(false);
      console.error(error);
      if (typeof onError === 'function') {
        onError(error);
      }
    },
    [onError]
  );

  const deleteUrl = React.useCallback(async () => {
    try {
      if (!adminFeats) {
        manageError('Operation not allowed');
        return;
      }

      if (!agamiId) {
        manageError('Invalid agami ID');
        return;
      }

      setIsLoading(true);

      const { data, error } = await singleDbUpsert({
        table: GEOXP_SPOTS_TABLE,
        upsertData: {
          id: agamiId,
          audio_signed_url: null,
          audio_signed_url_expires_at: null,
        },
      });

      if (error) {
        manageError(error);
        return;
      }

      setIsLoading(false);

      if (typeof onSuccess === 'function') {
        onSuccess(data);
      }
    } catch (err) {
      manageError(err);
    }
  }, [adminFeats, agamiId]);

  return {
    deleteUrl,
    isLoading,
  };
};
